const state = {
  isShowLoding: false
};

const mutations = {
  SET_SHOW_LOADING(state) {
    state.isShowLoding = true;
  },
  SET_HIDE_LOADING(state) {
    state.isShowLoding = false;
  }
};

const actions = {
  showLoading({ commit }) {
    commit('SET_SHOW_LOADING');
  },
  hideLoading({ commit }) {
    commit('SET_HIDE_LOADING');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import request from '../utils/request.js';
import { cache } from '@/utils/storage';

// 路由懒加载
const Login = () => import('../views/Login.vue');
const Reg = () => import('../views/Reg.vue');
const Mine = () => import('../views/Mine.vue');
const Home = () => import('../views/Home.vue');
const Search = () => import('../views/Search.vue');
const Cart = () => import('../views/Cart.vue');
const Discover = () => import('../views/Discover.vue');
const Details = () => import('../views/Details.vue');
const Category = () => import('../views/Category.vue');

//消除 Uncaught (in promise) Error: Redirected when going from "/xx" to "/xx" via a navigation guard 错误警告
const originalReplace = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
//安装路由插件
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      name: 'login',
      path: '/login',
      component: Login
    },
    {
      name: 'register',
      path: '/reg',
      component: Reg
    },
    {
      name: 'mine',
      path: '/mine',
      component: Mine
    },
    { name: 'home', path: '/home', component: Home },
    {
      path: '/search',
      component: Search
    },
    {
      path: '/cart',
      component: Cart,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/discover',
      component: Discover
    },
    {
      name: 'Details',
      path: '/details',
      component: Details
    },
    {
      name: 'Category',
      path: '/category',
      component: Category
    }
  ]
});

// 全局路由守卫
router.beforeEach(async function (to, from, next) {
  // 不能用this但是可以用router看到组件实例
  // console.log('beforeEach', router);
  // 获取token
  const { Authorization } = cache.get('userInfo') || {};
  // 登录
  if (Authorization) {
    // 如果登录之后 还想去登录页和注册页 直接挑战到首页
    if (['/login', '/reg'].includes(to.path)) {
      next('/');
      // 这两个页面需要登录权限
    } else if (to.meta.requiresAuth) {
      // 登入之后校验token有效性
      const { code } = await request.get('/user/verify', {
        headers: {
          Authorization
        }
      });
      if (code === 400) {
        // token已失效或被篡改
        next('/login');
      }
      next();
    } else {
      next();
    }
  } else {
    if (to.meta.requiresAuth) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router;

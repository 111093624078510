export const storage = {
  set(key, value) {
    //增加
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    //获取
    try {
      return JSON.parse(localStorage.get(key));
    } catch (e) {}
  },
  remove(key) {
    //删除
    localStorage.removeItem(key);
  }
};

export const cache = {
  set(key, value, type = 1) {
    value = JSON.stringify(value);
    type === 1 ? localStorage.setItem(key, value) : sessionStorage.setItem(key, value);
  },
  get(key, type = 1) {
    let value;
    type === 1 ? (value = localStorage.getItem(key)) : (value = sessionStorage.getItem(key));
    return JSON.parse(value);
  },
  remove(key, type = 1) {
    type === 1 ? localStorage.removeItem(key) : sessionStorage.removeItem(key);
  },
  clear(type = 1) {
    type === 1 ? localStorage.clear() : sessionStorage.clear();
  }
};

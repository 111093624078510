import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from './modules/user';
import setting from './modules/setting';
import { cache } from '@/utils/storage';

Vue.use(Vuex);

// 读取本地存储购物车信息
const addcartlist = cache.get('Cartlist') || [];

const store = new Vuex.Store({
  state: {
    addcartlist //本地储存购物车数据
  },
  mutations: {
    changea(state, playload) {
      state.a = playload;
      console.log('changea', playload);
    },
    // 更新当前本地存储数据
    changeaddcartlist(state, playload) {
      //state.addcartlist获取的是最新的数据
      cache.set('Cartlist', state.addcartlist);
    },

    // 删除
    removeaddcartlist(state, playload) {
      state.addcartlist = state.addcartlist.filter((item) => item.id !== playload);
      cache.set('Cartlist', state.addcartlist);
    }
  },
  actions: {},
  modules: {
    user,
    setting
  },
  plugins: [
    createPersistedState({
      storage: localStorage
    })
  ]
});

export default store;
